/* eslint-disable */
import {Vue, Options} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ControlTariff } from '@/store/tariff/types';

const namespace: string = 'tariff';
@Options({
	computed: {
		result() {
			let initialValue = 0;
			const sum = this.getSelectedModels.reduce(
				(accumulator: any, currentValue: any) => accumulator + currentValue.price * currentValue.day, initialValue
			);
			this.setAmountResult(sum);
		}
	}
})

export default class ControlTariffModelTariffChoicePage extends Vue {
	@State('tariff') tariff: ControlTariff | undefined;
	@Getter('getTarifs', {namespace}) getTarifs: any;
	@Getter('getModelsTariff', {namespace}) getModelsTariff: any;
	@Getter('getCurrentViewModel', {namespace}) getCurrentViewModel: any;
	@Getter('getSelectedModels', {namespace}) getSelectedModels: any;
	@Getter('getAmountResult', {namespace}) getAmountResult: any;
	@Action('fetchTariff', {namespace}) fetchTariff: any;
	@Action('fetchModelsTariff', {namespace}) fetchModelsTariff: any;
	@Action('storeTariff', {namespace}) storeTariff: any;
	@Mutation('setCurrentViewModel', {namespace}) setCurrentViewModel: any;
	@Mutation('setSelectedModels', {namespace}) setSelectedModels: any;
	@Mutation('setAmountResult', {namespace}) setAmountResult: any;

	modelProfile: Array<any> = [];

	modelIdValue(value: string) {
		return `modelId${value}`;
	}
	selectModel(model: Object) {
		this.setCurrentViewModel(model);
		// console.log(this.modelProfile);
	}
	handleMonth(model: any) {
		model.days = 28;
		this.setSelectedModels(model);
	}
	handleDay(model: any) {
		model.days = 1;
		this.setSelectedModels(model);
	}
	onSubmit() {
		this.storeTariff(this.getSelectedModels);
	}

	mounted() {
		this.fetchTariff();
		this.fetchModelsTariff();
	}
}
